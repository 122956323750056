import { ChakraProvider, Flex } from '@chakra-ui/react'

import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import NavBar from './NavBar';
import MapSearchPage from './Search/MapSearchPage';

function App() {
  const [removeAds, setRemoveAds] = useState(false);

  useEffect(() => {
    // Simulate an API call
    fetch('https://maxmypoint.com/authenticated', {
      credentials: 'include' // Include cookies for cross-origin requests
    })
      .then(response => response.json())
      .then(data => {
        // Assume the API returns a boolean indicating whether to add the script
        console.log('authenticated:', data);
        if (data.authenticated && (data.level === 'Gold' || data.level === 'Platinum')) {
          setRemoveAds(true);
          console.log('removeAds:', removeAds);
        }
      })
      .catch(error => console.error('Error fetching condition:', error));
  }, []);

  useEffect(() => {
    console.log('removeAds:', removeAds);
    if (removeAds) {
      const adsScripts = document.querySelectorAll('script[name="adsbygoogle"]');
      console.log('adsScripts:', adsScripts);
      adsScripts.forEach(ad => {
        ad.parentNode.removeChild(ad);
      });
      const adsIns = document.querySelectorAll('ins.adsbygoogle');
      console.log('adsIns:', adsIns);
      adsIns.forEach(ad => {
        ad.parentNode.removeChild(ad);
      });
    }
  }, [removeAds]);

  return (
    <HelmetProvider>
      <ChakraProvider>
        <Helmet>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4035047434593541"
            crossorigin="anonymous"></script>
        </Helmet>
        <Router>
          <Flex direction='column' justify='stretch' height='100%'>
            <NavBar />
            <Routes>
              <Route path='/' element={<MapSearchPage />} />
            </Routes>
          </Flex>
        </Router>
      </ChakraProvider>
    </HelmetProvider>
  );
}

export default App;
